import { CalculatorConfig } from "./calculators";

export class Site {
    display: string;
    key: string;
    code: string;
}
export class CalculatorSiteConfig {
    calculatorGuid: string;
    calculatorVersion: number;
    key: string;
    config: { [key: string]: any };
    siteCalculator: CalculatorConfig;
    version: number;
    isLatest: boolean;
    constructor(calcGuid: string, calcVersion: number, sitekey: string) {
        this.calculatorGuid = calcGuid;
        this.calculatorVersion = calcVersion;
        this.key = sitekey;
        this.config = {};
    }

}

export class categories{
    key: string;
    display: string;
}

export class SiteInfo extends Site {
    siteInfo?: SiteInformation;
    constructor(site: Site) {
        super();
        this.display = site?.display;
        this.key = site?.key;
        this.code = site?.code;
        this.siteInfo = new SiteInformation();
    }
}

export class SiteInformation {
    basicInfo: BasicSiteInfo;
    assumbtions: {[key:string]:SiteProcessCategory};
    defaultCharge: DefaultSiteCharge;
    priceFactors: SitePriceFactors = new SitePriceFactors();
sizeFactor: SizeFactor = new SizeFactor();
    constructor() {
        this.basicInfo = new BasicSiteInfo();
        this.assumbtions = {};
        this.defaultCharge = new DefaultSiteCharge();
        this.priceFactors = new SitePriceFactors();
    }
}

export class categoriesData{
    display:string;
    laborRate: number;
    key: string;
    minLot:number;
    activeInactive: number;

    constructor(display: string, key: string, laborRate?: number, minLot?: number, activeInactive?: number) {
        this.display = display;
        this.key = key ;
        this.laborRate = laborRate || null;
        this.minLot = minLot || 0;
        this.activeInactive = activeInactive || 1;
    }
}

export class BasicSiteInfo {
    address: string;
    footer1: string;
    footer2: string;
    footer3: string;
    constructor() {
        this.address = null;
        this.footer1 = null;
        this.footer2 = null;
        this.footer3 = null;
    }
}

export class DefaultSiteCharge {
    minPurchaseOrder: number;
    percentageAfterFirstMinLot: number;
    charges: { [key: string]: SiteCharge };
    constructor() {
        this.minPurchaseOrder = null;
        this.percentageAfterFirstMinLot = null;
        this.charges = {};
    }
}

export class SiteCharge {
    name: string;
    type: 'Percent' | 'Fixed' | null;
    value: number;
    order?: number;
    constructor() {
        this.name = null;
        this.type = null;
        this.value = null;
    }
}

export class SiteProcessCategory {
    laborRate: number;
    minLot: number;
    activeInactive: number;
    constructor() {
        this.laborRate = 0;
        this.minLot = 0;
        this.minLot = 0;
    }
}



export const categoriesKey = {
    "abrassiveClean": "AbrassiveClean",
    "anodize": "Anodize",
    "assemblyCleanAndPick": "AssemblyCleanAndPick",
    "generalPainting": "GeneralPainting",
    "masking": "Masking",
    "ndt": "NDT",
    "plating": "Plating",
    "pylons": "Pylons",
    "shotPeen": "ShotPeen",
    "tiSSProcessing": "TiSSProcessing"
};

export class SitePriceFactors {
    inflationFactor: InflationFactor;
    specification: Specification;
    competition: Competition;
    standardMargins: StandardMargins;
    constructor() {
        this.inflationFactor = new InflationFactor();
        this.specification = new Specification();
        this.competition = new Competition();
        this.standardMargins = new StandardMargins();
    }
}
export class InflationFactor {
    high: number;
    standard: number;
    low: number;
    constructor() {
        this.high = 0;
        this.standard = 0;
        this.low = 0;
    }
}
export class Specification {
    challenging: number;
    typical: number;
    complex: number;
    constructor() {
        this.challenging = 0;
        this.typical = 0;
        this.complex = 0;
    }
}
export class Competition {
    soleSource: number;
    typical: number;
    high: number;
    constructor() {
        this.high = 0;
        this.typical = 0;
        this.soleSource = 0;
    }
}
export class StandardMargins {
    labor: number;
    material: number;
    constructor() {
        this.labor = 0;
        this.material = 0;
    }
}
export class SizeFactor {
    xSmall: number;
    small: number;
    medium: number;
    large: number;
    xLarge: number;
    constructor() {
        this.small = 0;
        this.medium = 0;
        this.large = 0;
        this.xLarge = 0;
        this.xSmall = 0;
    }
}

import { Inject, Injectable, forwardRef } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
const GET_UNIVERSAL_CONFIG_QUERY = `query CategoriesQuery($categoryKey: String!) {
    UniversalConfig(where: {Key: {_eq: $categoryKey}}) {
      Json
    }
  }`;
  const GET_DISTINCT_TEMLATE_CONFIG_OPTION_QUERY = `query GetDitinct {
    TemplateConfig(distinct_on: --FieldName--) {
      --FieldName--
    }
  }`;
const UPDATE_UNIVERSAL_CONFIG_MUTATION = `mutation UpdateUniversalConfig($categoryKey: String!, $json: jsonb!) {
    update_UniversalConfig(where: {Key: {_eq: $categoryKey}}, _set: {Json: $json}) {
      affected_rows
    }
  }`;
@Injectable({ providedIn: 'root' })
export class UniversalConfigService {

    constructor(@Inject(forwardRef(() => Apollo)) private apollo: Apollo) { }

    async getUniversalConfig(categoryKey: string) {
        return await this.apollo.query<any[]>({
            query: gql(GET_UNIVERSAL_CONFIG_QUERY),
            variables: { categoryKey },
        }).pipe(map((resp: any) => (resp?.data?.UniversalConfig[0]?.Json))).toPromise()
    }

    async getDistinctTemplateConfigField(optionKey: string): Promise<string[]> {
        const query = GET_DISTINCT_TEMLATE_CONFIG_OPTION_QUERY.replace('--FieldName--', optionKey).replace('--FieldName--', optionKey);
        return await this.apollo.query<any[]>({
            query: gql(query),
            variables: { optionKey },
        }).pipe(map((resp: any) => (resp?.data?.TemplateConfig?.map(opt => opt[optionKey])))).toPromise()
    }

    async updateUniversalConfig(categoryKey: string, json: any) {
        return await this.apollo.mutate({
            mutation: gql(UPDATE_UNIVERSAL_CONFIG_MUTATION),
            variables: { categoryKey, json },
        }).pipe(map((resp: any) => resp?.data?.update_UniversalConfig?.affected_rows)).toPromise();
    }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IPermission } from '@discoverer/core';
export class SitePermission {
    siteCode: 'ENTERPRISE' | 'CHR' | 'CMF' | 'DYN' | 'PPI' | 'FPI' | 'HWB' | 'BSF' | 'MAP' | 'TRI' | 'TPI'  | 'CAM' | 'CPI';
    level: 'ADMIN' | 'ESTIMATOR'
    constructor(permission: IPermission) {
        this.siteCode = permission.key as 'ENTERPRISE' | 'CHR' | 'CMF' | 'DYN' | 'PPI' | 'FPI' | 'HWB' | 'BSF' | 'MAP' | 'TRI' | 'TPI' | 'CAM' | 'CPI';
        this.level = permission.resource as 'ADMIN' | 'ESTIMATOR';
    }
}

@Injectable({
    providedIn: 'root'
})
export class SitePermissionService {
    public oSitePermissions: Observable<SitePermission[]>;
    public _$sitePermissions: ReplaySubject<SitePermission[]> = new ReplaySubject<SitePermission[]>();
    public oEnterPrisePermissions: Observable<boolean>;
    public _$enterPrisePermissions: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(
        private _httpClient: HttpClient,
    ) {
        this.oSitePermissions = this._$sitePermissions.asObservable();
        this.oEnterPrisePermissions = this._$enterPrisePermissions.asObservable();
    }
    public async getAllPermissions() {
        try {
            var permissions = (await this._httpClient.get<IPermission[]>(`api/es/v1/user/prctl/resources/APPID::PRCTL`).toPromise() || []);
            this._$sitePermissions.next(permissions.map(permission => new SitePermission(permission)))
            // if (permissions.length > 0) {
                this._$enterPrisePermissions.next(!!permissions.find(permission => permission.key === 'ENTERPRISE' && permission.resource === 'ADMIN'))
            // } else {
            //     this._$enterPrisePermissions.next(true)
            // }
        } catch {

        }
    }
    public checkIsEnterprise() {
        return this._$enterPrisePermissions.getValue()
    }
}

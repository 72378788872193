import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService, NavigationService, UserData, UserDataService } from '@discoverer/app-core';
import { SiteControllerService } from './ignite/services/site-controller.service';
import { Subscription, combineLatest } from 'rxjs';
import { APP_NAVITEMS, ESTIMATOR_VALID_ROUTES, SETTINGS_NAVITEMS } from './shared/navigation-items-service';
import { SitePermissionService } from './ignite/services/site-permission.service';
import { take } from 'rxjs/internal/operators/take';
import { Router } from '@angular/router';
import { debounceTime, skip } from 'rxjs/operators';
import { Calculator } from './ignite/models/calculators';
import { CalculatorService } from './ignite/services/calculator.service';
import { UserDataServiceInterface } from '@discoverer/attachments/services/user-data-service-interface.service';



@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        { provide: UserDataServiceInterface, useExisting: UserDataService }
    ]
})
export class AppComponent {
    public loggedIn = false;
    title = 'docepipeline';
    _sub: Subscription
    constructor(
        private _userDataService: UserDataServiceInterface,
        private _authService: AuthService,
        private _navigationService: NavigationService,
        private _siteControllerService: SiteControllerService,
        private _calculatorService: CalculatorService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _sitePermissionService: SitePermissionService,
        private _router: Router,

    ) {
        console.log("Hello World")
        //this._authService.monitorForTokenParamAndFetchUser();
        this._authService.gatewayIdentityUserObservable.subscribe(user => {
            if (!!user) {
                console.log("🟢Authe")
                const userData = new UserData();
                userData.name = user.fullName;
                userData.email = user.email;
                userData.id = user.userId;
                userData.token = user.token;
                userData.roles = new Set<string>(user.roles);
                this._userDataService.setUser(userData);
                this._siteControllerService.getSites();
                !!this._sub && this._sub?.unsubscribe()
                this._sub = combineLatest([this._navigationService.navigation$, this._siteControllerService.oSelectedSitePermission.pipe(skip(1))]).subscribe(async (res) => {
                    const [navigation, permission] = res;
                    const isEnterPrise = await this._sitePermissionService.oEnterPrisePermissions.pipe(take(1)).toPromise();

                    if (permission?.isAdmin || isEnterPrise) {
                        navigation.items = [APP_NAVITEMS, SETTINGS_NAVITEMS];
                    } else {
                        if (!ESTIMATOR_VALID_ROUTES.includes(this._router.url) && !this._router.url.includes('/prctl/estimates/')) this._router.navigate(['prctl/reports/estimate/default']);
                        navigation.items = [APP_NAVITEMS];
                    }

                    if (!isEnterPrise && (this._router.url.includes("formula") || this._router.url.includes("definition"))) {
                        if (permission?.isAdmin) {
                            const guid = (await this._calculatorService.oCurrentCalculator.pipe(debounceTime(0), take(1)).toPromise()).guid
                            this._router.navigate([`prctl/calculators/${guid}/configuration`], { queryParamsHandling: 'preserve' });
                        } else {
                            this._router.navigate(['prctl/reports/estimate/default']);
                        }
                    }

                    this._changeDetectorRef.detectChanges();
                })
            }
        });
        document.addEventListener("wheel", function (event) {
            ((document?.activeElement as any)?.type === "number") && (document?.activeElement as any)?.blur();
        });
        // Fix issue with new chrome material select bug:- https://github.com/angular/components/issues/28905
        document.addEventListener('DOMNodeInserted', function () {
            const elements = document.querySelectorAll('[aria-owns]');
            elements?.forEach(element => {
                element?.removeAttribute('aria-owns');
            });
        });
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { CalculatorConfig, FieldInfo } from '../models/calculators';
const CALC_CONTROLLER = 'api/calculator/';

export interface CalculatorMapping {
    Version: number;
    Guid: string;
}
@Injectable({
    providedIn: 'root'
})
export class CalculatorService {
    public oCurrentCalculator: Observable<CalculatorConfig>;
    public _$currentCalculator: BehaviorSubject<CalculatorConfig> = new BehaviorSubject<CalculatorConfig>(null);

    constructor(private _httpClient: HttpClient) {
        this.oCurrentCalculator = this._$currentCalculator.asObservable();
    }
    public async getCalcConfig(guid: string) {
        return await this._httpClient.get<CalculatorConfig>(CALC_CONTROLLER + guid).toPromise() || new CalculatorConfig(guid);

    }
    public async getCalculator(guid: string) {
        const calc = await this.getCalcConfig(guid);
        this.setCalculator(calc);
    }

    public async setCalculator(calculatorConfig: CalculatorConfig = new CalculatorConfig(uuidv4())) {
        this._$currentCalculator.next(calculatorConfig);
    }

    public async clearCalculator() {
        this._$currentCalculator.next(null);
    }

    public async cloneCalculator(cloneCalcGuid: string, newName: string): Promise<boolean> {
        try {
            const response = await this._httpClient.post('/api/calculator/clone', {
                CloneCalcGuid: cloneCalcGuid,
                NewName: newName
            }).toPromise();
            return response ? true : false;
        } catch (error) {
            console.error('Error cloning calculator:', error);
            return false;
        }
    }

    public async saveCalculator(guid = uuidv4(), calculatorConfig: CalculatorConfig = new CalculatorConfig(uuidv4())) {
        const calc = await this._httpClient.post<CalculatorConfig>(CALC_CONTROLLER + guid, calculatorConfig).toPromise();
        this.setCalculator(calc);
    }

    public async updateCalcFormula(fieldName: string, field: FieldInfo) {
        let calc = await this.getCurrentCalc();
        calc.jsonMetdata.fields[fieldName] = field;
    }

    public async updateCalcName(fieldName: string, field: FieldInfo) {
        let calc = await this.getCurrentCalc();
        calc.jsonMetdata.fields[fieldName] = field;
    }

    public getCurrentCalc() {
        return this.oCurrentCalculator.pipe(take(1)).toPromise();
    }
    public async changeCalcActiveStatusController(guid: string, activate: boolean = true) {
        return await this._httpClient.put<any>(`${CALC_CONTROLLER}activate/${guid}?activate=${activate}`, null).toPromise();
    }
    public async GetCalculatorsByCategory() {
        return await this._httpClient.get<any>(`${CALC_CONTROLLER}active`).toPromise();
    }
    public async getCalcs(guids: CalculatorMapping[]) {
        return await this._httpClient.post<CalculatorConfig[]>(`${CALC_CONTROLLER}multi/version`, guids).toPromise();
    }
    public async getLatestCalcs(guids: string[]) {
        return await this._httpClient.post<CalculatorConfig[]>(`${CALC_CONTROLLER}multi`, guids).toPromise();
    }
    public async deleteCalc(guid: string) {
        const calculatorConfig = await this.getCalcConfig(guid);
        calculatorConfig.isDeleted = true;
        return this.saveCalculator(guid, calculatorConfig);
    }
    public async addSiteToCalculator(guid: string, code: string) {
        const sucess = await this._httpClient.put<boolean>(`${CALC_CONTROLLER}activate/${guid}/${code}`, null).toPromise();
        this.getCalculator(guid);
        return sucess
    }

}
